import { app } from "@/main";

const common = {
  /**
   * 压缩图片
   * @param file input获取到的文件
   * @param callback 回调函数，压缩完要做的事，例如postRequest请求等。
   */
  compressFile(file, callback) {
    const _this = this;
    let fileInit = file;
    let fileObj = file;
    let reader = new FileReader();
    reader.readAsDataURL(fileObj); //转base64
    reader.onload = function (e) {
      _this.compressFileByUrl(e.target.result, callback, fileInit.name);
    };
  },
  /**
   * 压缩图片
   * @param url 文件base64地址
   * @param callback 回调函数，压缩完要做的事，例如postRequest请求等。
   */
  compressFileByUrl(url, callback, fileName = "a.jpg", type = "image/jpeg") {
    let image = new Image(); //新建一个img标签（还没嵌入DOM节点)
    image.src = url;
    image.onload = function () {
      let canvas = document.createElement("canvas"), // 新建canvas
        context = canvas.getContext("2d"),
        imageWidth = image.width / 2, //压缩后图片的大小
        imageHeight = image.height / 2,
        data = "";
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      context.drawImage(image, 0, 0, imageWidth, imageHeight);
      data = canvas.toDataURL(type); // 输出压缩后的base64
      let arr = data.split(","),
        mime = arr[0].match(/:(.*?);/)[1], // 转成blob
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let files = new window.File(
        [new Blob([u8arr], { type: mime })],
        fileName,
        { type: type }
      ); // 转成file
      let file_obj = {
        file: files,
        content: data,
      };
      callback(file_obj); // 回调
    };
  },
  // 附件上传
  uploadFileFN(file) {
    var formData = new FormData(); // 声明一个FormData对象
    formData.append("file", file.file); // 'file' 这个名字要和后台获取文件的名字一样;
    formData.append("name", file.file.name);
    // resolve代表成功 reject失败 都是一个函数
    return new Promise((resolve, reject) => {
      app.config.globalProperties
        .postRequestFile("/docrepo/uploadAction!upload.action", formData)
        .then((res) => {
          resolve({ ...res.data });
        });
    });
  },
};

export default common;
