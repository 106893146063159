import { createApp, nextTick } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import components from "./components";
import VConsole from "vconsole/dist/vconsole.min.js";
import "@/assets/css/index.less";
// import "@/assets/font/iconfont.css"

export const app = createApp(App);
// 2. 引入组件样式
import "vant/lib/index.css";

app.use(Vant);
app.use(components);

// let _VConsole = new VConsole()
//引入axios
import axios from "axios";
/*封装请求方法*/
import {
  postRequest,
  postRequests,
  getRequest,
  getRequests,
  reLogin,
  postRequestFile,
  postRequestBlob,
} from "@/utils/http.js";

nextTick(() => {
  app.config.globalProperties.$http = axios;
  app.config.globalProperties.getRequest = getRequest;
  app.config.globalProperties.getRequests = getRequests;
  app.config.globalProperties.postRequest = postRequest;
  app.config.globalProperties.postRequests = postRequests;
  app.config.globalProperties.postRequestFile = postRequestFile;
  app.config.globalProperties.postRequestBlob = postRequestBlob;
  app.config.globalProperties.reLogin = reLogin;
});

app.config.globalProperties.precentFormatter = function (value, fix = 0) {
  if (isNaN(Number(value))) {
    return "0%";
  } else {
    return value.toFixed(fix) + "%";
  }
};

import common from "./utils/common";
app.config.globalProperties.common = common;

import moment from "moment";
app.config.globalProperties.dateFmt = function (
  input,
  formatString = "YYYY-MM-DD",
  letter = ""
) {
  return input ? moment(input).format(formatString) : letter;
};

app.config.globalProperties.toIso = function (date) {
  return moment(date).format();
};

app.config.globalProperties.theDomian =
  document.location.protocol + "//" + document.domain;

router.beforeEach((to, from, next) => {
  const paramWx = getParams();
  if (!sessionStorage.getItem("apiHref")) {
    const url = window.location.origin + to.fullPath;
    sessionStorage.setItem("apiHref", url);
  }
  if (to.path !== "/login") {
    sessionStorage.setItem("lastPage", window.location.href);
  }
  next();
});
function getParams() {
  const _this = this;
  const paramWx = [];
  var paramList = window.location.search.substr(1).split("&");
  for (var i in paramList) {
    var _param = paramList[i].split("=");
    paramWx[_param[0]] = _param[1];
  }
  return paramWx;
}

app.use(store).use(router).mount("#app");
