<template>
  <div :class="{ 'tree-container': index == 0 }">
    <van-collapse
      v-model="treeExpand"
      title-class="cell__title"
      v-for="tree in treeData"
      :key="tree.taskID"
      @change="onExpand"
    >
      <template v-if="tree.isLeaf">
        <van-cell
          :style="{ paddingLeft: index * 20 + 'px' }"
          :class="{ 'cancel-cell': tree.isCancel }"
          class="flex_jc leaf-cell"
          @click="viewTask(tree.taskID)"
        >
          <template #icon v-if="tree.isCancel">
            <van-icon name="clear" color="#f00" size="25" />
          </template>
          <template #title>
            <div class="task-left">
              <div class="task-title">{{ tree.subject }}</div>
              <div class="task-progress">
                {{ precentFormatter(tree.progress) }}
              </div>
            </div>
          </template>
          <template #value>
            <div class="task-value">
              <div>
                <van-button
                  v-if="!tree.isCancel"
                  type="primary"
                  size="mini"
                  @click.stop="addTask(tree.taskID)"
                  >添加</van-button
                >
                <van-button
                  v-if="tree.taskStatus == '01' && !tree.isCancel"
                  type="danger"
                  size="mini"
                  @click.stop="cancelTask(tree.taskID)"
                  >取消</van-button
                >
              </div>
              <div>{{ dateFmt(tree.planEndTime) }}</div>
            </div>
          </template>
        </van-cell>
      </template>
      <template v-else>
        <van-collapse-item :name="tree.taskID">
          <template #title>
            <div class="task-left">
              <div class="task-title">{{ tree.subject }}</div>
              <div class="task-progress">
                {{ precentFormatter(tree.progress) }}
              </div>
            </div>
          </template>
          <template #value>
            <div class="task-value">
              <van-button
                type="primary"
                size="mini"
                @click.stop="addTask(tree.taskID)"
                >添加</van-button
              >
              <div>{{ dateFmt(tree.planEndTime) }}</div>
            </div>
          </template>
          <template #icon>
            <van-icon class="expand-arrow" name="arrow-down" size="20" />
          </template>
          <template #right-icon>
            <van-icon
              name="info"
              color="#ed6a0c"
              size="25"
              class="view-icon"
              @click.stop="viewInfo(tree)"
            />
          </template>
          <cd-tree
            :tree-data="tree.subTaskList"
            :index="index + 1"
            @btn-click="
              (data) => {
                $emit('btn-click', data);
              }
            "
            @leaf-click="
              (data) => {
                $emit('leaf-click', data);
              }
            "
            @btn-cancel="
              (data) => {
                $emit('btn-cancel', data);
              }
            "
          ></cd-tree>
        </van-collapse-item>
      </template>
    </van-collapse>
    <!-- <div v-for="tree in treeData" :key="tree.taskID">
            <span :style="{paddingLeft: index*10+'px'}">{{ tree.subject }}</span>
            <cd-tree :tree-data="tree.subTaskList" v-if="tree.subTaskList" :index="index+1"></cd-tree>
        </div> -->
    <van-popup v-model:show="popup" style="width: 95%">
      <div class="task-detail">
        <van-cell
          title="任务标题"
          :value="popupInfo.subject"
          titleClass="cell-title"
          valueClass="cell-value"
        />
        <van-cell
          title="任务内容"
          titleClass="cell-title"
          valueClass="cell-value"
        >
          <div v-html="popupInfo.contents"></div>
        </van-cell>
        <van-cell
          title="计划开始"
          titleClass="cell-title"
          valueClass="cell-value"
        >
          <div>{{ dateFmt(popupInfo.planBeginTime) }}</div>
        </van-cell>
        <van-cell
          title="计划结束"
          titleClass="cell-title"
          valueClass="cell-value"
        >
          <div>{{ dateFmt(popupInfo.planEndTime) }}</div>
        </van-cell>
        <van-cell
          title="开始时间"
          titleClass="cell-title"
          valueClass="cell-value"
        >
          <div>{{ dateFmt(popupInfo.planBeginTime) }}</div>
        </van-cell>
        <van-cell title="进度" titleClass="cell-title" valueClass="cell-value">
          <div>{{ precentFormatter(popupInfo.progress) }}</div>
        </van-cell>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { showDialog } from "vant";
export default {
  name: "CdTree",
  props: {
    index: {
      type: Number,
      default: 0,
    },
    treeData: {
      type: Array,
      default: new Array(),
    },
  },
  data() {
    return {
      treeExpand: [],
      popup: false,
      popupInfo: {},
    };
  },
  mounted() {
    const treeExpand = sessionStorage.getItem("treeExpand_" + this.index);
    if (treeExpand) {
      this.treeExpand = JSON.parse(treeExpand);
    }
  },
  methods: {
    viewInfo(info) {
      this.popupInfo = info;
      this.popup = true;
    },
    addTask(id) {
      this.$emit("btn-click", id);
    },
    cancelTask(id) {
      this.$emit("btn-cancel", id);
    },
    viewTask(id) {
      this.$emit("leaf-click", id);
    },
    onExpand(activeNames) {
      sessionStorage.setItem(
        "treeExpand_" + this.index,
        JSON.stringify(activeNames)
      );
    },
  },
};
</script>

<style lang="less" scoped>
.tree-container {
  // padding-right: 16px;
  .expand-arrow {
    transform: rotate(-90deg);
    transition: transform 0.3s;
  }
  .van-collapse-item__title--expanded {
    .expand-arrow {
      transform: rotate(0);
    }
  }
  .view-icon {
    margin-left: 15px;
  }
  /deep/ .van-collapse-item {
    .van-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      .cell__title{
        flex: 1.5;
      }
    }
    .cancel-cell {
      background-color: #eee;
    }
    .van-collapse-item__content {
      padding: 0;
    }
  }

  .task-left,
  .task-value {
    padding-left: 15px;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .task-left {
    align-items: flex-start;
    .task-title {
      font-weight: bold;
      text-align: left;
    }
    .task-progress {
      color: #969799;
    }
  }
  .task-value {
    align-items: flex-end;
  }

  /deep/.task-detail {
    .cell-title {
      text-align: left;
      width: 100px;
      flex: none;
      color: #969696;
    }
    .cell-value {
      text-align: left;
      color: #333;
    }
  }
}
</style>
